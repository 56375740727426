export var handArmFunctions = [{
  label: 'Level 1',
  key: 'STABILIZER'
}, {
  label: 'Level 2',
  key: 'ASSIST'
}, {
  label: 'Level 3',
  key: 'FUNCTIONAL'
}];
export var missionTypes = [{
  label: 'ACTIVITY',
  key: 'ACTIVITY'
}, {
  label: 'EXERCISE',
  key: 'BASICS'
}, {
  label: 'VIDEO',
  key: 'VIDEO'
}, {
  label: 'READING',
  key: 'READING'
}, {
  label: 'QUIZ',
  key: 'QUIZ'
}];
export var NOT_SET_NUMBER = 9999999999;