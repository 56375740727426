import request from '@/utils/request';
export var defaultMissionInfData = {
  handArmFunction: '',
  type: 'ACTIVITY',
  title: '',
  image: '',
  day: null,
  description: '',
  createdAt: new Date(),
  links: [],
  tags: [],
  meta: '',
  isFree: false,
  shareLink: ''
};
export var getMissionInfos = function getMissionInfos(params) {
  return request({
    url: '/connect/api/v1/missionInfos',
    method: 'get',
    params: params
  });
};
export var getMissionInfo = function getMissionInfo(id, params) {
  return request({
    url: "/connect/api/v1/missionInfos/".concat(id),
    method: 'get',
    params: params
  });
};
export var createMissionInfo = function createMissionInfo(data) {
  return request({
    url: '/connect/api/v1/missionInfos',
    method: 'post',
    data: data
  });
};
export var updateMissionInfo = function updateMissionInfo(data) {
  return request({
    url: "/connect/api/v1/missionInfos/".concat(data.id),
    method: 'put',
    data: data
  });
};
export var deleteMissionInfo = function deleteMissionInfo(id) {
  return request({
    url: "/connect/api/v1/missionInfos/".concat(id),
    method: 'delete'
  });
};